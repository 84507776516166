.word-anim {
  &__container {
    display: flex;
    &._await-anim {
      .word-anim {
        &__letter {
          opacity: 0;
          transform: translate3d(0, 60px, 0);
        }
      }
    }
  }

  &__letter {
    display: block;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .8s var(--text-transition),
    -webkit-opacity .8s var(--text-transition),
    transform .8s var(--text-transition),
    -webkit-transform .8s var(--text-transition);

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: calc(#{$i} * .06s);
      }
    }
  }
}

@include respond-up('large') {
  .word-anim {

  }
}

@include respond-up('medium') {
  .word-anim {

  }
}

@include respond('medium') {
  .word-anim {

  }
}

@include respond-down('medium') {
  .word-anim {

  }
}

@include respond-down('small') {
  .word-anim {

  }
}