.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 500;
  overflow: hidden;
  transition: height 1.4s var(--image-transition);

  &__inner {
    position: relative;
    height: 100vh;
  }

  &__value-wrap {
    position: absolute;
    overflow: hidden;
  }

  &__value {
    font-style: normal;
    font-weight: normal;
    line-height: 1em;
    letter-spacing: 0.05em;
    color: var(--red-color-dark);
    transition: transform 1.4s var(--text-transition);
  }

  &__background {
    z-index: 1001;
    background-color: var(--red-color-dark);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 1.4s var(--image-transition);
  }

  &__background-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__logo-wrap {
    z-index: 1000;
    position: absolute;
    overflow: hidden;
  }

  &__logo {
    opacity: 0.16;
    transition: transform 1.4s var(--text-transition);
    svg {
      width: 100%;
      height: 100%;
    }

    &_red {
      opacity: 1;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  &._await-anim {
    .preloader {
      &__value,
      &__logo {
        transform: translate3d(0, 100%, 0);
      }
    }
  }
}

@include respond-up('large') {
  .preloader {
    &__value-wrap {
      top: calc((89 / 784) * 100vh);
      left: 11vw;
    }

    &__value {
      font-size: calc((320 / 784) * 100vh);
    }

    &__logo-wrap {
      right: calc((123 / 784) * 100vh);
      bottom: calc((123 / 784) * 100vh);
      width: calc((386 / 1400) * 100vw);
      //height: calc((123 / 1400) * 100vw);
    }
  }
}

@include respond-up('medium') {
  .preloader {

  }
}

@include respond('medium') {
  .preloader {
    &__value-wrap {
      top: 10vh;
      left: 11vw;
    }

    &__value {
      font-size: calc((260 / 768) * 100vw);
    }

    &__logo-wrap {
      right: 20vw;
      bottom: 20vh;
      width: calc((386 / 768) * 100vw);
      //height: calc((123 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .preloader {

  }
}

@include respond-down('small') {
  .preloader {
    &__value-wrap {
      top: 20vh;
      left: 10vw;
    }

    &__value {
      font-size: 160px;
    }

    &__logo-wrap {
      right: #{$spacer-small}px;
      bottom: 20vh;
      width: calc((250 / 375) * 100vw);
      //height: calc((79 / 375) * 100vw);
    }
  }
}