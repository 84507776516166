.slide-six-part {
  position: relative;

  &__wrap-image-container {
    image-rendering: pixelated;
  }

  &__item-title-wrap {
    position: absolute;
    display: flex;
  }

  &__item-title {
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.03em;
    color: var(--red-color-dark);
  }

  &__items {
    display: flex;
  }

  &__item {
    display: flex;
  }

  &__wrap-image {
    overflow: hidden;
    flex: 0 0 auto;
  }

  &__wrap-video {
    overflow: hidden;
    flex: 0 0 auto;
  }

  &__picture,
  &__wrap-video-container {
    position: relative;
    display: flex;
    padding-top: 72%;
  }

  &__image,
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;

    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .8s var(--text-transition),
                -webkit-opacity .8s var(--text-transition),
                transform .8s var(--text-transition),
                -webkit-transform .8s var(--text-transition);
    &._await-anim {
      opacity: 0;
      transform: translate3d(0, 60px, 0);
    }
  }

  &__number {
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 35px;
    line-height: 44px;
    text-transform: uppercase;
    color: var(--primary-color);
    flex: 0 0 18%;
    margin-top: -8px;
  }

  &__description {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.35em;
    color: var(--primary-color);
    flex: 0 1 auto;
  }
}

@include respond-up('large') {
  .slide-six-part {
    padding-left: 193px;

    &:first-child {
      padding-left: 224px;
    }

     &__item {
       padding-top: 48px;

      &:not(:last-child) {
        margin-right: 141px;
      }
    }

    &__item-title-wrap {
      top: calc((405 / 784) * 100vh);
      left: 193px;
    }

    &__item-title {
      font-size: 245px;
      line-height: 283px;
      transition: transform 1.4s var(--image-transition) .25s;

      &._await-anim {
        transform: translate3d(-104px, 0, 0);
      }
    }

    &__wrap-image-container,
    &__wrap-video-container {
      transform: scale(1.9);
      transition: transform 1.4s var(--image-transition),
                  -webkit-transform 1.4s var(--image-transition);
    }

    &__wrap-image,
    &__wrap-video {
      margin-right: 42px;
    }

    &__text-wrap {
      width: max-content;
      max-width: 282px;
      padding-top: 34px;
    }

    &__picture,
    &__wrap-video-container {
      width: calc((577 / 784) * 100vh);
      height: calc((418 / 784) * 100vh);
    }
  }
}

@include respond-up('medium') {
  .slide-six-part {

  }
}

@include respond('medium') {
  .slide-six-part {
    &__item-title {
      font-size: 160px;
      line-height: 173px;
    }

    &__item-title-wrap {
      left: #{$spacer-medium}px;
    }

    //&__items-wrap {
    //  margin: 0 -#{$spacer-medium}px;
    //  padding-top: 130px;
    //}

    //&__items {
    //  padding: 0 #{$spacer-medium}px;
    //}

    &__item {
      &:not(:last-child) {
        margin-right: #{$spacer-medium}px;
      }
    }
  }
}

@include respond-down('medium') {
  .slide-six-part {
    &__picture,
    &__wrap-video-container {
      width: 80vw;

      &._await-anim {
        .slide-six-part {
          &__image,
          &__video {
            transform: scale(1.4);
          }
        }
      }
    }

    &__image,
    &__video {
      transform: scale(1);
      will-change: transform;
      transition: transform 1.4s var(--image-transition),
                  -webkit-transform 1.4s var(--image-transition);
    }

    &__item-title-wrap {
      top: 0;
    }

    &__items {
      //overflow: scroll;
      //&::-webkit-scrollbar {
      //  display: none;
      //}
    }

    &__item {
      flex-direction: column;
    }

    &__text-wrap {
      margin-top: 10px;
    }
  }
}

@include respond-down('small') {
  .slide-six-part {
    &__item-title-wrap {
      left: #{$spacer-small}px;
    }

    &__item-title {
      font-size: 100px;
      line-height: 100px;
    }

    &__items-wrap {
      margin: 0 -#{$spacer-small}px;
      padding-top: 75px;
    }

    &__items {
      padding: 0 #{$spacer-small}px;
    }

    &__item {
      &:not(:last-child) {
        margin-right: #{$spacer-small}px;
      }
    }
  }
}
