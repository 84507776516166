.slide-third-part {
  display: flex;
  position: relative;

  &__images {
    display: flex;
  }

  &__picture-first,
  &__wrap-video-container {
    display: flex;
    overflow: hidden;
    position: relative;
    padding-top: 70%;
    height: 100%;
    width: 100%;

    &._await-anim {
      .slide-third-part {
        &__image-first,
        &__video {
          transform: scale(1.5);
        }
      }
    }
  }

  &__image-first,
  &__image,
  &__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: transform 1.4s var(--image-transition),
                -webkit-transform 1.4s var(--image-transition);
  }

  &__picture {
    position: relative;
    display: flex;
    overflow: hidden;
    padding-top: 74%;
  }

  &__title-wrap {
    background-color: var(--red-color-dark);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 20;
  }

  &__title {
    color: #fff;
    font-weight: normal;
    font-size: 60px;
    line-height: 1.2em;
    letter-spacing: 0.03em;
  }

  &__description {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.25em;
    color: var(--primary-color);
  }

  &__column-first,
  &__column {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
  }

  &__column-first-wrap {
    display: flex;
  }

  &__column-bottom {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid var(--primary-color-opacity-33);
    padding-top: 26px;
    margin-top: 20px;
  }

  &__column-number {
    font-family: var(--h-font);
    font-weight: 500;
    font-size: 35px;
    line-height: 44px;
    text-transform: uppercase;
    color: var(--primary-color);
    flex: 0 0 18%;
    margin-top: -8px;
  }

  &__column-title {
    flex: 0 1 auto;
  }

  &__column-description {
    font-family: var(--font-secondary);
    font-weight: 300;
    font-size: 16px;
    line-height: 1.35em;
    color: var(--primary-color);
  }

  &__another-columns {
    z-index: 25;
  }

  &__another-columns-inner {
    display: flex;
    align-items: flex-start;
    z-index: 25;
  }

  &__column-image {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &__description-wrap,
  &__column-bottom {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .8s var(--text-transition),
                -webkit-opacity .8s var(--text-transition),
                transform .8s var(--text-transition),
                -webkit-transform .8s var(--text-transition);
    &._await-anim {
      opacity: 0;
      transform: translate3d(0, 60px, 0);
    }
  }
}

@include respond-up('large') {
  .slide-third-part {
    padding: 0 100px;

    &__column-image {
      width: calc((381 / 784) * 100vh);
      height: calc((283 / 784) * 100vh);
    }

    &__column-image-container {
      transform: scale(1.9);
      transition: transform 1.4s var(--image-transition),
                  -webkit-transform 1.4s var(--image-transition);
    }

    &__picture-first,
    &__wrap-video-container {
      width: calc((562 / 784) * 100vh);
      height: calc((395 / 784) * 100vh);

      //&._await-anim {
      //  .slide-third-part {
      //    &__image-first,
      //    &__video {
      //      transform: scale(1.5);
      //    }
      //  }
      //}
    }

    &__picture {
      width: calc((381 / 784) * 100vh);
      height: calc((283 / 784) * 100vh);
    }

    //&__image-first,
    //&__video {
    //  transform: scale(1);
    //  transition: transform 1.4s var(--image-transition),
    //              -webkit-transform 1.4s var(--image-transition);
    //}

    &__title-wrap {
      top: 34vh;
    }

    &__description-wrap {
      margin-top: 115px;
      max-width: 367px;
    }

    &__column-first-wrap {
      flex-direction: column;
      justify-content: center;
    }

    &__column-first {
      padding: 0 0 5vh 96px;
      max-height: 750px;
      height: 100%;
    }

    &__another-columns {
      margin-left: -60px;
      margin-top: 25vh;
    }

    &__column {
      max-width: calc((381 / 784) * 100vh);
      &:not(:last-child) {
        margin-right: 60px;
      }
    }
  }
}

@include respond-up('medium') {
  .slide-third-part {
    &__title-wrap {
      width: 193px;
      height: 193px;
    }
  }
}

@include respond('medium') {
  .slide-third-part {
    padding-top: #{$spacer-medium}px;

    &__picture-first,
    &__wrap-video-container {
      width: calc(100vw - 80px);
    }

    &__column-image {
      height: calc((283 / 768) * 100vw);
    }

    &__picture {
      width: calc((381 / 768) * 100vw);
      height: calc((283 / 768) * 100vw);
    }

    &__title-wrap {
      right: 20px;
      top: calc((452 / 768) * 100vw);
    }

    &__description-wrap {
      max-width: 68%;
      margin-top: #{$spacer-medium}px;
    }

    &__another-columns {
      margin: 80px -#{$spacer-medium}px 0 -#{$spacer-medium}px;
    }

    &__another-columns-inner {
      padding: 0 #{$spacer-medium}px;
    }

    &__column {
      flex: 0 0 46%;
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}

@include respond-down('medium') {
  .slide-third-part {
    flex-direction: column;

    &__another-columns {
      margin-top: 80px;
    }

    &__another-columns-inner {
      justify-content: space-between;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__picture,
    &__wrap-video-container {
      &._await-anim {
        .slide-third-part {
          &__image,
          &__video {
            transform: scale(1.4);
          }
        }
      }
    }

    &__image,
    &__video {
      transform: scale(1);
      will-change: transform;
      transition: transform 1.4s var(--image-transition),
                  -webkit-transform 1.4s var(--image-transition);
    }
  }
}

@include respond-down('small') {
  .slide-third-part {
    --picture-height: calc((232 / 375) * 100vw);
    margin-top: #{$spacer-small}px;
    padding-top: #{$spacer-small}px;

    &__picture-first,
    &__wrap-video-container {
      width: calc(100vw - 44px);
      height: var(--picture-height);
    }

    &__picture {
      width: calc(100vw - 44px);
    }

    &__title-wrap {
      width: 120px;
      height: 120px;
      left: 15px;
      top: calc(var(--picture-height) - 40px);
    }

    &__title {
      font-size: 40px;
    }

    &__description-wrap {
      margin-top: 60px;
    }

    &__another-columns {
      margin: 50px -#{$spacer-small}px 0 -#{$spacer-small}px;
    }

    &__another-columns-inner {
      padding: 0 #{$spacer-small}px;
    }

    &__column {
      max-width: 80vw;
      &:not(:last-child) {
        margin-right: #{$spacer-small}px;
      }
    }
  }
}