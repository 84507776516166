.slide-part {
  &__title {
    border-bottom: 5px solid #FFFFFF;
  }

  &__description {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.25em;
  }

  &__item-title {
    color: var(--primary-color);
    font-weight: normal;
    line-height: 1.2em;
    letter-spacing: 0.03em;
  }

  &__item-description {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.35em;
  }
}

@include respond-up('large') {
  .slide-part {
    &__title {
      padding-bottom: 39px;
    }
  }
}

@include respond-up('medium') {
  .slide-part {
    &__item-title {
      font-size: 60px;
    }
  }
}

@include respond('medium') {
  .slide-part {

  }
}

@include respond-down('medium') {
  .slide-part {
    &__title {
      padding-bottom: 15px;
    }
  }
}

@include respond-down('small') {
  .slide-part {
    &__item-title {
      font-size: 40px;
    }
  }
}