.slides {
  &__item-six-part-list {
    position: relative;
  }

  &__item-six-part-list-inner {
    display: flex;
  }

  &__item-six-part-title-wrap {
    position: absolute;
    display: flex;
  }

  &__item-six-part-title {
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.03em;
    color: var(--red-color-dark);
  }

  &__item-main {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__picture-main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    transition: transform 20s linear;
    transform: scale(1.1);

    &._await-anim {
      transform: scale(1);
    }
  }

  &__image-main {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-main-top {
    z-index: 20;
  }

  &__item-main-bottom {
    display: flex;
    align-content: center;
    justify-content: space-between;
    z-index: 20;
  }

  &__item-main-title {
    color: var(--red-color-dark);
    font-weight: normal;
    line-height: 1em;
    letter-spacing: 0.05em;
  }

  &__item-main-description {
    max-width: 400px;
    color: #fff;
    font-weight: normal;
    line-height: 1.35em;
    transition-delay: .2s;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .8s var(--text-transition),
                -webkit-opacity .8s var(--text-transition),
                transform .8s var(--text-transition),
                -webkit-transform .8s var(--text-transition);
    &._await-anim {
      opacity: 0;
      transform: translate3d(0, 60px, 0);
    }
  }

  &__item-main-button {
    background-color: var(--red-color);
    width: 153px;
    height: 153px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 63px;
    }
  }

  &__item {
    position: relative;

    &._white {
      background-color: white;
    }

    &._black {
      background-color: var(--background-color-black);

      .slides__item-column {
        border-color: var(--color-white-opacity-33);
      }

      .slide-first-part__title,
      .slide-first-part__description,
      .slide-first-part__item-description,
      .slide-column__description,
      .slide-part__title,
      .slide-part__description,
      .slide-part__item-description,
      .slide-fifth-part__item-title,
      .slide-third-part__description,
      .slide-third-part__column-number,
      .slide-third-part__column-title,
      .slide-fifteen-part__description,
      .slide-fifteen-part__column-number,
      .slide-fifteen-part__column-title,
      .slide-six-part__number,
      .slide-six-part__description,
      .slide-column__bottom-counter-current-title,
      .slide-column__bottom-counter-total {
        color: #fff;
      }

      .slide-part__title {
        border-bottom-color: var(--red-color);
      }

      .slide-fifth-part__item-title-wrap {
        background-color: var(--red-color-dark);
      }

      .slide-third-part__column-bottom, .slide-fifteen-part__column-bottom {
        border-top-color: var(--color-white-opacity-33);
      }

      .slide-column__bottom-counter {
        color: #fff;
        &::before {
          background-color: #fff;
        }
      }

      .arrows__link {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }

    &._red {
      background-color: var(--red-color-dark);

      .slide-first-part__title,
      .slide-first-part__description,
      .slide-first-part__item-description,
      .slide-column__description,
      .slide-part__title,
      .slide-part__description,
      .slide-part__item-description,
      .slide-column__bottom-counter-current-title,
      .slide-column__bottom-counter-total {
        color: #fff;
      }

      .slide-part__title {
        border-bottom-color: #fff;
      }

      .slide-first-part__item-title {
        color: #fff;
        border-bottom-color: #fff;
      }

      .slide-rail__title {
        color: #fff;
        &::after {
          background-color: #fff;
        }
      }
    }

    &_nineteen {
      .slide-fifth-part {

        &__item-title-wrap {
          background-color: var(--red-color-dark);
        }
        &__item-title {
          color: white;
        }
        &__title {
          border-bottom-color: var(--red-color-dark);
        }
      }

    }
  }

  &__value-title {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.2em;
    color: var(--color-white-opacity-33);
  }

  &__bar {
    height: 1px;
    width: 100vw;
    background-color: var(--t);
    position: absolute;
    top: 0;
  }
}

@include respond-up('x-large') {
  .slides {
    &__item-main-title {
      font-size: 384px;
    }
  }
}

@include respond('large') {
  .slides {
    &__item-main-title {
      font-size: calc((320 / 1600) * 100vw);
    }
  }
}

@include respond-up('large') {
  .slides {
    &__item-six-part-title-wrap {
      top: calc((405 / 784) * 100vh);
      left: 193px;
    }

    &__item-six-part-title {
      font-size: 245px;
      line-height: 283px;
      transition: transform 1.4s var(--image-transition) .25s;

      &._await-anim {
        transform: translate3d(-104px, 0, 0);
      }
    }

    &__item-column {
      border-right: 1px solid var(--color-black-opacity-33);
      &:nth-child(2) {
        border-left: 1px solid var(--color-black-opacity-33);
      }
    }

    &__inner {
      //overflow-x: scroll;
      //overflow-y: hidden;
      position: fixed;
      top: 0;
      bottom: 0;
      //left: 0;
      //right: 0;
      //&::-webkit-scrollbar {
      //  display: none;
      //}
    }

    &__list {
      display: flex;
      position: relative;
      z-index: 300;
      mix-blend-mode: difference;
    }

    &__item {
      //position: relative;
      height: 100vh;
      width: fit-content;
      flex: 0 0 auto;
      display: flex;
      padding: 65px 0 110px;
      &_third,
      &_sixteen,
      &_sixth,
      &_seventh,
      &_eighteen {
        padding-right: 100px;
      }

      &_fifth,
      &_tenth,
      &_ninth,
      &_twelfth,
      &_thirteenth {
        padding-right: 140px;
      }

      &_eighth {
        padding-right: 40px;
      }

      &_nineteen {
        .slide-fifth-part {
          padding-right: 227px;
        }
      }

      &_tenth {
        .slide-fifth-part__description-wrap {
          margin-top: 46px;
        }
      }
      //padding: 65px 114px 110px 0;


      &_thirteenth {
        .slide-six-part {
          &__item-title-wrap {
            left: 0;
          }

          &:first-child {
            padding-left: 144px;
          }
        }
      }
    }

    &__item-main {
      //height: 100vh;
      //width: 100vw;
      flex: 0 0 100vw;
      padding: 89px 122px 123px 144px;
    }

    &__progress {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 3vh;
      width: 100vw;
      height: 22px;
      z-index: 200;
    }
  }
}

@include respond-up('medium') {
  .slides {
    &__item-main-description {
      font-size: 25px;
    }
  }
}

@include respond('medium') {
  .slides {
    &__item-six-part-title {
      font-size: 160px;
      line-height: 173px;
    }

    &__item-main-title {
      font-size: calc((200 / 768) * 100vw);
    }

    &__item-main {
      padding: 60px #{$spacer-medium}px;
    }

    &__item {
      padding: 60px #{$spacer-medium}px;
    }

    &__item-column {
      &:nth-child(2) {
        margin-top: 60px;
      }
    }

    &__item-six-part {
      &:not(:last-child) {
        margin-right: #{$spacer-medium}px;
      }
    }

    &__item-six-part-list {
      margin: 0 -#{$spacer-medium}px;
    }

    &__item-six-part-list-inner {
      padding: 130px #{$spacer-medium}px 0;
    }

    &__item-six-part-title-wrap {
      left: #{$spacer-medium}px;
    }
  }
}

@include respond-down('medium') {
  .slides {
    &__item-column {
      border-bottom: 1px solid var(--color-black-opacity-33);
      &:nth-child(2) {
        border-top: 1px solid var(--color-black-opacity-33);
      }
    }

    &__item-six-part-list-inner {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__progress {
      display: none;
    }

    &__item-main-button {
      transform: rotate(90deg);
    }

    &__item-six-part-title-wrap {
      top: 0;
    }

    &__item-main {
      overflow: hidden;
    }
  }
}

@include respond-down('small') {
  .slides {
    &__item-six-part-list {
      margin: 0 -#{$spacer-small}px;
    }

    &__item-six-part-list-inner {
      padding: 75px #{$spacer-small}px 0;
    }

    &__item-six-part-title-wrap {
      left: #{$spacer-small}px;
    }

    &__item-six-part {
      &:not(:last-child) {
        margin-right: #{$spacer-small}px;
      }
    }

    &__item-main {
      padding: #{$spacer-small}px #{$spacer-small}px 40px;
    }

    &__item {
      padding: 40px #{$spacer-small}px;
    }

    &__item-column {
      &:nth-child(2) {
        margin-top: 50px;
      }
    }

    &__item-main-title {
      font-size: calc((100 / 375) * 100vw);
    }

    &__item-main-bottom {
      flex-wrap: wrap;
      margin: -20px 0;
    }

    &__item-main-description {
      font-size: 20px;
      padding: 20px 0;
      margin: 0 20px 30px 0;
    }

    &__item-main-button {
      padding: 20px 0;
      width: 120px;
      height: 120px;
    }

    &__item-main-top {
      margin-top: 100px;
    }

    &__item-six-part-title {
      font-size: 100px;
      line-height: 100px;
    }
  }
}