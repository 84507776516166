.progress-bar-list {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: inherit;
  overflow: hidden;
  width: 100vw;
  transition: opacity 2.5s var(--image-transition);
  opacity: 0;

  &__items {
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 0;
    left: 10vw;
    height: inherit;
    width: 90vw;
    overflow: hidden;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;

    &:last-child {
      .progress-bar-list {
        &__item-line {
          margin: 0 0 0 20px;
        }
      }
    }
  }

  &__item-line {
    display: flex;
    align-items: center;
    height: 1px;
    width: 100%;
    margin: 0 20px;
    position: relative;

    &_main {
      width: calc(10vw - 20px);
      margin: 0 20px 0 0;
      .progress-bar-list__item-line-background {
        width: 80%;
        transition: width .6s var(--text-transition);

        &._full {
          width: 100%;
        }
      }
    }
  }

  &__item-line-background {
    position: absolute;
    width: 0;
    height: 100%;
    background-color: #fff;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &__line {
    height: 2px;
    width: 100vw;
    background-color: var(--t);
    position: absolute;
    top: 0;
  }

  &__item-title {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.2em;
    transition: color .6s var(--text-transition);
  }

  &._white {
    .progress-bar-list {
      &__item-title {
        color: var(--color-white-opacity-50);
      }

      &__item-line {
        background-color: var(--color-white-opacity-50);
      }
    }
  }

  &._black {
    .progress-bar-list {
      &__item-title {
        color: black;
      }

      &__item-line {
        background-color: var(--color-black-opacity-33);
      }
    }
  }
}

@include respond-up('large') {
  .progress-bar-list {

  }
}

@include respond-up('medium') {
  .progress-bar-list {

  }
}

@include respond('medium') {
  .progress-bar-list {

  }
}

@include respond-down('medium') {
  .progress-bar-list {

  }
}

@include respond-down('small') {
  .progress-bar-list {

  }
}