.slide-four-part {
  display: flex;

  &__images-wrap {
    display: flex;
    position: relative;

    &:first-child {
      .slide-four-part {
        &__picture,
        &__wrap-video-container {
          padding-top: 68%;
        }
      }
    }
    .slide-four-part {
      &__picture {
        padding-top: 94%;
      }
    }
  }

  &__item-title-wrap {
    background-color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 20;
  }

  &__picture,
  &__wrap-video-container {
    display: flex;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;

    &._await-anim {
      .slide-four-part {
        &__image,
        &__video {
          transform: scale(1.5);
        }
      }
    }
  }

  &__image,
  &__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: transform 1.4s var(--image-transition),
                -webkit-transform 1.4s var(--image-transition);
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__item-description {
    &:not(:last-child) {
      padding-bottom: 20px;
    }

    &:not(:first-child) {
      padding-top: 20px;
      border-top: 1px solid var(--color-white-opacity-25);
    }
  }

  &__text-wrap-bottom {
    display: none;
  }

  &_with-item-description {
    .slide-four-part {
      &__text-wrap-bottom {
        display: block;
      }
    }
  }

  &__title,
  &__description,
  &__item-description {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .8s var(--text-transition),
                -webkit-opacity .8s var(--text-transition),
                transform .8s var(--text-transition),
                -webkit-transform .8s var(--text-transition);
    &._await-anim {
      opacity: 0;
      transform: translate3d(0, 60px, 0);
    }
  }

  &_fourteenth {
    .slide-four-part {
      &__title {
        border-bottom-color: var(--red-color);
      }

      &__item-description {
        &:nth-child(n + 2) {
          border-color: var(--color-black-opacity-33);
        }
      }

      &__item-title-wrap {
        background-color: var(--primary-color);
      }

      &__item-title {
        color: white;
      }
    }
  }
}

@include respond-up('large') {
  .slide-four-part {
    padding: 0 100px;

    &__item-description {
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          transition-delay: calc(#{$i} * .06s);
        }
      }
    }

    //&__picture,
    //&__wrap-video-container {
    //  &._await-anim {
    //    .slide-four-part {
    //      &__image,
    //      &__video {
    //        transform: scale(1.5);
    //      }
    //    }
    //  }
    //}

    &__image-wrap {
      &:first-child {
        height: 100%;
        margin-right: -70px;
        .slide-four-part {
          &__picture,
          &__wrap-video-container {
            width: calc((562 / 784) * 100vh);
            height: calc((381 / 784) * 100vh);
          }
        }
      }

      .slide-four-part {
        &__picture {
          width: calc((364 / 784) * 100vh);
          height: calc((345 / 784) * 100vh);
        }
      }
    }

    //&__image,
    //&__video {
    //  transform: scale(1);
    //  transition: transform 1.4s var(--image-transition),
    //              -webkit-transform 1.4s var(--image-transition);
    //}

    &__images-wrap {
      align-items: flex-end;
      padding-bottom: 60px;
    }

    &__item-title-wrap {
      right: -96px;
      bottom: 9vh;
    }

    &__text-wrap {
      width: max-content;
      max-width: 372px;
      margin: 13vh 0 0 178px;
    }

    &__description-wrap {
      margin-top: 53px;
    }

    &__text-wrap-bottom {
      margin-top: 50px;
      width: 100%;
    }
  }
}

@include respond-up('medium') {
  .slide-four-part {
    &__item-title-wrap {
      width: 193px;
      height: 193px;
    }
  }
}

@include respond('medium') {
  .slide-four-part {
    --picture-height: 420;

    &__image-wrap {
      &:first-child {
        .slide-four-part {
          &__picture {
            width: 620px;
            height: calc((var(--picture-height) / 768) * 100vw);
          }

          &__wrap-video-container {
            width: calc((620 / 786) * 100vw);
            height: calc((var(--picture-height) / 768) * 100vw);
          }
        }
      }

      .slide-four-part {
        &__picture {
          width: calc((364 / 768) * 100vw);
          height: calc((345 / 768) * 100vw);
        }
      }

      &:not(:first-child) {
        position: absolute;
        right: 0;
        top: 50%;
      }
    }

    &__item-title-wrap {
      top: calc(((var(--picture-height) + 60) / 768) * 100vw);
      left: 15px;
      transform: translateY(-50%);
    }

    &__text-wrap {
      padding-top: calc((130 / 768) * 100vw);
      max-width: 66%;
    }

    &__title {
      //max-width: 57%;
    }

    &_with-item-description {
      .slide-four-part {
        &__image-wrap {
          &:not(:first-child) {
            top: 50%;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .slide-four-part {
    flex-direction: column;

    &__images-wrap {
      flex-direction: column;
    }

    &__description-wrap,
    &__text-wrap-bottom {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .slide-four-part {
    &__item-title-wrap {
      width: 120px;
      height: 120px;
    }

    &__item-title-wrap {
      right: 15px;
      bottom: -50px;
    }

    &__image-wrap {
      &:not(:first-child) {
        display: none;
      }

      .slide-four-part {
        &__picture,
        &__wrap-video-container {
          width: calc(100vw - 44px);
        }
      }
    }

    &__text-wrap {
      margin-top: 50px;
    }
  }
}