.slide-first-part {
  &__logo {
    position: absolute;
    z-index: 200;
  }

  &__title,
  &__description,
  &__item-description {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .8s var(--text-transition),
                -webkit-opacity .8s var(--text-transition),
                transform .8s var(--text-transition),
                -webkit-transform .8s var(--text-transition);
    &._await-anim {
      opacity: 0;
      transform: translate3d(0, 60px, 0);
    }
  }

  &__title {
    grid-area: title;
    display: flex;
  }

  &__description-wrap {
    grid-area: description;
    display: flex;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.25em;
    color: var(--primary-color);
  }

  &__item-title-wrap {
    grid-area: item-title;
    display: flex;
  }

  &__item-title-container {
    position: relative
  }

  &__item-title {
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.03em;
    color: var(--red-color-dark);
  }

  &__item-description-wrap {
    grid-area: item-description;
    display: flex;
  }

  &__item-description {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.35em;
    color: var(--primary-color);
  }

  &__image-wrap {
    grid-area: image-wrap;
    z-index: 10;
    overflow: hidden;
  }

  &__picture,
  &__wrap-video-container {
    display: flex;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;

    &._await-anim {
      .slide-first-part {
        &__image,
        &__video {
          transform: scale(1.5);
        }
      }
    }
  }

  &__image,
  &__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: transform 1.4s var(--image-transition),
                -webkit-transform 1.4s var(--image-transition);
  }

  &__slide-rail {
    grid-area: rail;
  }

  &_second,
  &_fifth {
    display: grid;
    grid-column-gap: 79px;
    grid-template-areas: 'title image-wrap'
                         'description image-wrap'
                         '. item-description';
    .slide-first-part {
      &__picture,
      &__wrap-video-container {
        padding-top: 76%;
      }

      &__description {
        transition-delay: .1s;
      }

      &__title {
        align-items: flex-end;
      }

      &__description-wrap {
        margin-top: 23px;
      }

      &__description {
        max-width: 293px;
      }

      &__item-description-wrap {
        justify-content: flex-end;
        padding-top: 44px;
      }

      &__item-description {
        max-width: 363px;
      }
    }
  }

  &_third,
  &_seventh,
  &_eighteen,
  &_eleventh,
  &_sixteen {
    display: grid;

    .slide-first-part {
      &__picture,
      &__wrap-video-container {
        padding-top: 49%;
      }

      &__item-description {
        transition-delay: .1s;
      }

      &__title {
        max-width: 364px;
        margin: -14px 72px 0 0;
        height: max-content;
        border-bottom: 5px solid var(--red-color-dark);
      }

      &__description {
        max-width: 293px;
      }

      &__item-title {
        position: relative;
        padding-right: 181px;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 154px;
          height: 1px;
          background-color: var(--red-color-dark);
          transform: translate3d(0, -50%, 0);
        }
      }

      &__item-description {
        margin-top: 24px;
      }
    }
  }
}

@include respond-up('large') {
  .slide-first-part {
    &__item-title {
      font-size: 245px;
      line-height: 283px;
    }

    &__item-title-wrap {
      position: absolute;
      top: calc((455 / 784) * 100vh);
      left: 140px;
    }

    &__logo {
      top: 5px;
      left: 10px;
      svg {
        path {
          transition: fill .3s;
        }
      }
    }

    //&__title,
    //&__description,
    //&__item-description {
    //  opacity: 1;
    //  transform: translate3d(0, 0, 0);
    //  transition: opacity .8s var(--text-transition),
    //              -webkit-opacity .8s var(--text-transition),
    //              transform .8s var(--text-transition),
    //              -webkit-transform .8s var(--text-transition);
    //  &._await-anim {
    //    opacity: 0;
    //    transform: translate3d(0, 60px, 0);
    //  }
    //}

    //&__picture,
    //&__wrap-video-container {
    //  &._await-anim {
    //    .slide-first-part {
    //      &__image,
    //      &__video {
    //        transform: scale(1.5);
    //      }
    //    }
    //  }
    //}

    //&__image,
    //&__video {
    //  transform: scale(1);
    //  transition: transform 1.4s var(--image-transition),
    //              -webkit-transform 1.4s var(--image-transition);
    //}

    &_second,
    &_fifth {
      padding: 0 140px;
      grid-template-rows:  calc((221 / 784) * 100vh) calc((221 / 784) * 100vh) auto;

      .slide-first-part {
        &__picture,
        &__wrap-video-container {
          width: calc((576 / 784) * 100vh);
          height: calc((442 / 784) * 100vh);
        }

        &__image-wrap {
          width: calc((576 / 784) * 100vh);
        }

        &__description-wrap {
          min-width: 293px;
        }
      }
    }

    &_third,
    &_seventh,
    &_eleventh,
    &_sixteen {
      padding: 0 147px 0 430px;
    }

    &_eighteen {
      padding: 0 147px 0 140px;
    }

    &_third,
    &_seventh,
    &_eighteen,
    &_eleventh,
    &_sixteen {
      grid-template-rows: auto auto auto;
      grid-template-areas: 'image-wrap image-wrap'
                           'title description'
                           'title item-description';
      .slide-first-part {
        &__picture,
        &__wrap-video-container {
          width: calc((700 / 784) * 100vh);
          height: calc((341 / 784) * 100vh);
        }

        &__image-wrap {
          width: calc((700 / 784) * 100vh);
          padding-bottom: 56px;
        }

        &__item-description {
          max-width: 270px;
        }

        &__title {
          padding-bottom: 39px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .slide-first-part {

  }
}

@include respond('medium') {
  .slide-first-part {
    &__item-title {
      font-size: 160px;
      line-height: 173px;
      max-height: 140px;
    }

    &__logo {
      top: #{$spacer-medium}px;
      left: #{$spacer-medium}px;
    }

    &_second,
    &_fifth {
      grid-column-gap: #{$spacer-medium}px;
      grid-template-areas: 'title image-wrap'
                         'description image-wrap'
                         'item-title item-description';
      .slide-first-part {
        &__image-wrap {
          display: flex;
          justify-content: flex-end;
        }

        &__picture,
        &__wrap-video-container {
          height: calc((272 / 768) * 100vw);
          width: calc((354 / 768) * 100vw);
        }

        &__title {
          align-items: flex-end;
          margin-top: 20px;
          max-width: 293px;
        }

        &__description-wrap {
          margin-top: 23px;
        }

        &__description {
          max-width: 293px;
        }

        &__item-description-wrap {
          justify-content: flex-end;
          padding-top: #{$spacer-medium}px;
        }

        &__item-description {
          max-width: 363px;
        }
      }
    }

    &_fifth {
      .slide-first-part {
        &__item-title {
          font-size: 135px;
        }
      }
    }

    &_third,
    &_seventh,
    &_eighteen,
    &_eleventh,
    &_sixteen {
      grid-template-areas: 'image-wrap image-wrap'
                           'title description'
                           'title item-description'
                           'rail rail';
      .slide-first-part {
        &__picture,
        &__wrap-video-container {
          height: calc((335 / 768) * 100vw);
          width: calc(100vw - 80px);
        }

        &__image-wrap {
          padding-bottom: 40px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .slide-first-part {
    &_third,
    &_seventh,
    &_eighteen,
    &_eleventh,
    &_sixteen {
      .slide-first-part {
        &__item-description-wrap {
          z-index: 20;
        }

        &__title {
          padding-bottom: 18px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .slide-first-part {
    &__logo {
      top: #{$spacer-small}px;
      left: #{$spacer-small}px;
    }

    &__item-title {
      font-size: 100px;
      line-height: 100px;
    }

    &__picture,
    &__wrap-video-container {
      width: calc(100vw - 44px);
    }

    &_second {
      padding-top: 40px;
    }

    &_second,
    &_fifth {
      grid-column-gap: #{$spacer-medium}px;
      grid-template-areas: 'title'
                           'description'
                           'item-title'
                           'image-wrap'
                           'item-description';
      .slide-first-part {
        &__image-wrap {
          margin-top: 20px;
        }

        &__item-title-wrap {
          margin: 20px 0 -50px;
        }

        &__item-description-wrap {
          padding-top: 20px;
        }
      }
    }

    &_seventh,
    &_eighteen,
    &_eleventh {
      grid-template-areas: 'title'
                           'description'
                           'rail'
                           'image-wrap';
    }

    &_third,
    &_sixteen {
      grid-template-areas: 'title'
                           'description'
                           'rail'
                           'image-wrap'
                           'item-description';
    }

    &_third,
    &_seventh,
    &_eighteen,
    &_eleventh,
    &_sixteen {
      grid-row-gap: #{$spacer-small}px;

      .slide-first-part {
        &__slide-rail {
          margin-bottom: -50px;
        }

        &__title {
          margin-right: 0;
        }

        &__item-description {
          margin-top: unset;
        }

        &__description {
          max-width: unset;
        }
      }
    }
  }
}