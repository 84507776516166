.slide-rail {
  &__wrapper {
    position: relative;
    white-space: nowrap;
    width: auto;
    display: inline-flex;
  }

  &__group-container {
    position: relative;
    white-space: nowrap;
    width: auto;
    display: inline-flex;
  }

  &__title {
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.03em;
    color: var(--red-color-dark);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      height: 1px;
      display: inline-block;
      background-color: var(--red-color-dark);
    }
  }

  &__track-container {
    width: auto;
    white-space: nowrap;
    display: inline-flex;
  }
}

@include respond-up('large') {
  .slide-rail {
    position: absolute;
    width: 100vh;
    top: 50%;
    left: 11rem;
    transform: translate3d(-50%, -50%, 0) rotate(-90deg);
    white-space: nowrap;
    overflow: hidden;

    &__wrapper {
      transform: translateX(-50%);
    }

    &__title {
      font-size: 245px;
      line-height: 283px;
      display: inline-flex;
      padding-right: 181px;
      margin-right: 19px;

      &::after {
        top: 126px;
        width: 154px;
      }
    }
  }
}

@include respond('medium') {
  .slide-rail {
    margin: 20px -40px 0;
    max-height: 140px;
    &__title {
      font-size: 160px;
      line-height: 173px;
      padding-right: 80px;

      &::after {
        width: 80px;
      }
    }
  }
}

@include respond-down('medium') {
  .slide-rail {
    overflow: hidden;
    padding: 0 #{$spacer-medium}px;

    &__title {
      &:not(:first-child) {
        display: none;
      }
      &::after {
        display: none;
      }

      &_visible {
        will-change: transform;
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        transition: opacity .8s var(--text-transition),
                    -webkit-opacity .8s var(--text-transition),
                    transform .8s var(--text-transition),
                    -webkit-transform .8s var(--text-transition);
        &._await-anim {
          opacity: 0;
          -webkit-transform: translate3d(0, 60px, 0);
          transform: translate3d(0, 60px, 0);
        }
      }
    }

    &__track-container {
      &:not(:first-child) {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .slide-rail {
    margin: 0 -22px;
    padding: 0 #{$spacer-small}px;

    &__title {
      font-size: 100px;
      line-height: 100px;
      padding-right: 80px;

      &::after {
        width: 50px;
      }
    }
  }
}