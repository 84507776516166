/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/
$spacer-smaller: 22;
$spacer-small: 22;
$spacer-medium: 40;
$spacer-large: 40;

$grid: (
  small: (
    from: 0,
    to: 767,
    spacer: 20
  ),
  medium: (
    from: 768,
    to: 1023,
    spacer: 30
  ),
  large: (
    from: 1024,
    to: 1920,
    spacer: 40
  ),
  x-large: (
    from: 1921,
    spacer: 40
  )
);

:root {
  --large-indent: calc((100% - ((var(--max-row-width) * 1px) - (#{$spacer-large} * 2px))) / 2);
  --medium-indent: calc(#{$spacer-medium} * 1px);
  --small-indent: calc(#{$spacer-small} * 1px);

  --max-row-width: 1280;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: 'Oranienbaum', sans-serif;
  --font-secondary: 'Roboto Condensed', sans-serif;
  --h-font: 'Druk Cyr', sans-serif;

  --t: transparent;

  --primary-color: #071827;
  --alert-color: #b50000;
  --default-transition: all 0.4s;
  --primary-color-opacity: rgba(1, 199, 103, .85);

  --background-color-black: #1A1919;

  --primary-color-opacity-33: rgba(7, 24, 39, 0.33);
  --color-white-opacity-25: rgba(255, 255, 255, 0.25);
  --color-white-opacity-33: rgba(255, 255, 255, 0.33);
  --color-white-opacity-50: rgba(255, 255, 255, 0.5);
  --color-black-opacity-33: rgba(0, 0, 0, 0.33);
  --red-color: #F00D16;
  --red-color-dark: #EF0D17;
  --blue-color: #0D267A;

  --image-transition: cubic-bezier(.165,.84,.44,1);
  --text-transition: cubic-bezier(.25,.46,.45,.94);
}