.slide-fifth-part {
    display: flex;

    &__images-wrap {
        display: flex;
        position: relative;
    }

    &__item-title-wrap {
        background-color: #fff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 20;

    }

    &__image-wrap {
        &:first-child {
            .slide-fifth-part {
                &__picture {
                    padding-top: 109%;
                }
            }
        }

        .slide-fifth-part {
            &__picture {
                padding-top: 68%;
            }
        }
    }

    &__picture {
        display: flex;
        overflow: hidden;
        position: relative;
        height: 100%;
        width: 100%;

        &._await-anim {
            .slide-fifth-part {
                &__image {
                    transform: scale(1.5);
                }
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: transform 1.4s var(--image-transition),
                    -webkit-transform 1.4s var(--image-transition);
    }

    &__text-wrap {
        display: flex;
        flex-direction: column;
    }

    &__item-description {
        padding-bottom: 20px;
        &:not(:first-child) {
            padding-top: 20px;
            border-top: 1px solid var(--color-white-opacity-25);
        }
    }

    &__description-wrap {
        position: relative;
    }

    &__description-icon {
        width: 56px;
        height: 45px;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__white-block {
        width: 91px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: none;
    }

    &__text-wrap-top,
    &__text-wrap-middle,
    &__description-wrap {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: opacity .8s var(--text-transition),
        -webkit-opacity .8s var(--text-transition),
        transform .8s var(--text-transition),
        -webkit-transform .8s var(--text-transition);
        &._await-anim {
            opacity: 0;
            transform: translate3d(0, 60px, 0);
        }
    }
}

@include respond-up('large') {
    .slide-fifth-part {
        padding-right: 100px;
        //margin-left: -91px;

        &__text-wrap-item {
            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    transition-delay: calc(#{$i} * .06s);
                }
            }
        }

        //&__picture {
        //    &._await-anim {
        //        .slide-fifth-part {
        //            &__image {
        //                transform: scale(1.5);
        //            }
        //        }
        //    }
        //}

        //&__image {
        //    transform: scale(1);
        //    transition: transform 1.4s var(--image-transition),
        //                -webkit-transform 1.4s var(--image-transition);
        //}

        &__images-wrap {
            align-items: center;
        }

        &__image-wrap {
            &:first-child {
                height: 100%;
                margin-right: -70px;
                .slide-fifth-part {
                    &__picture {
                        width: calc((563 / 784) * 100vh);
                        height: calc((616 / 784) * 100vh);
                    }
                }
            }

            .slide-fifth-part {
                &__picture {
                    width: calc((364 / 784) * 100vh);
                    height: calc((250 / 784) * 100vh);
                }
            }
        }

        &__item-title-wrap {
            right: -96px;
            top: 11vh;
        }

        &__text-wrap {
            width: max-content;
            max-width: 372px;
            margin: 5vh 0 0 241px;
        }

        &__description-wrap {
            margin-top: 144px;
        }

        &__text-wrap-middle {
            margin-top: 60px;
        }

        &__description-icon {
            left: -80px;
        }

        &__white-block {
            display: block;
        }
    }
}

@include respond-up('medium') {
    .slide-fifth-part {
        &__item-title-wrap {
            width: 193px;
            height: 193px;
        }

        &__description-icon {
            position: absolute;
            top: 0;
        }
    }
}

@include respond('medium') {
    .slide-fifth-part {
        margin-bottom: 40px;
        &__image-wrap {
            &:not(:first-child) {
                .slide-fifth-part {
                    &__picture {
                        width: calc(100vw - 80px);
                    }
                }
            }
        }

        &__item-title-wrap {
            bottom: -90px;
        }

        &__text-wrap {
            margin-top: 50px;
            padding: 0 70px;
        }

        &__description-icon {
            left: -70px;
        }
    }
}

@include respond-down('medium') {
    .slide-fifth-part {
        flex-direction: column;

        &__images-wrap {
            flex-direction: column;
        }

        &__image-wrap {
            &:first-child {
                display: none;
            }
        }

        &__text-wrap-item {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        &__item-title-wrap {
            right: 15px;
        }
    }
}

@include respond-down('small') {
    .slide-fifth-part {
        margin-bottom: 22px;
        &__item-title-wrap {
            width: 120px;
            height: 120px;
        }

        &__image-wrap {
            &:not(:first-child) {
                .slide-fifth-part {
                    &__picture {
                        width: calc(100vw - 44px);
                    }
                }
            }
        }

        &__item-title-wrap {
            bottom: -60px;
        }

        &__description {
            margin-top: 10px;
        }

        &__text-wrap {
            margin-top: 50px;
        }
    }
}