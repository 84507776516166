.slide-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &__title,
  &__description,
  &__subtitle {
    will-change: transform;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: opacity .8s var(--text-transition),
                -webkit-opacity .8s var(--text-transition),
                transform .8s var(--text-transition),
                -webkit-transform .8s var(--text-transition);
    &._await-anim {
      opacity: 0;
      -webkit-transform: translate3d(0, 60px, 0);
      transform: translate3d(0, 60px, 0);
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 135%;
    color: white;

  }

  &__title {
    font-weight: normal;
    font-size: 80px;
    line-height: 1.2em;
    letter-spacing: 0.03em;
    color: var(--red-color-dark);
  }

  &__description {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.35em;
    color: var(--primary-color);
    margin-top: 24px;
    transition-delay: .1s;
  }

  &__top {
    margin-top: -13px;
  }

  &__picture {
    position: relative;
    display: flex;
    padding-top: 90%;
  }

  &__wrap-image {
    overflow: hidden;
  }

  //&__slider-item-wrap {
  //  width: 314px;
  //  height: 285px;
  //}

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__wrap-video {
    position: relative;
    overflow: hidden;
  }

  &__wrap-video-container {
    width: 314px;
    height: 285px;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    z-index: 10;
    object-fit: cover;
  }

  &__bottom-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__bottom-counter {
    display: flex;
    justify-content: space-between;
    width: 49px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 1px;
      width: 17px;
      background-color: var(--primary-color);
    }
  }

  &__bottom-counter-current-title {
    display: flex;
    justify-content: flex-end;
    width: 8px;
  }

  &__arrows {
    margin: 0 -20px;
    .arrows__link {
      &:first-child {
        padding: 20px 10px 20px 20px;
      }
      &:last-child {
        padding: 20px 20px 20px 10px;
      }
    }
  }

  &__bottom-counter-current-title,
  &__bottom-counter-total {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 1.35em;
    color: var(--primary-color);
  }

  &__wrap-image-container {
    will-change: transform;
    transition: transform 1.4s var(--image-transition),
                -webkit-transform 1.4s var(--image-transition);
  }

}

@include respond-up('large') {
  .slide-column {
    padding: 0 30px;
    max-width: 374px;
    align-items: flex-start;

    &__wrap-image-container {
      transform: scale(1.9);
      -webkit-transform: scale(1.9);
    }

    &__image {
      will-change: transform;
      //transition: transform .4s var(--image-transition),
      //            -webkit-transform .4s var(--image-transition);
    }

    &__wrap-video-container {
      will-change: transform;
      transform: scale(1.9);
      -webkit-transform: scale(1.9);
      transition: transform 1.4s var(--image-transition),
                  -webkit-transform 1.4s var(--image-transition);
    }

    &__video {
      will-change: transform;
      //transition: transform .4s var(--image-transition),
      //            -webkit-transform .4s var(--image-transition);
    }

    &__slider-items {
      margin-top: 24px;
    }
  }
}

@include respond-up('medium') {
  .slide-column {
    &__picture {
      width: 314px;
      height: 285px;
    }
  }
}

@include respond('medium') {
  .slide-column {
    padding: #{$spacer-medium}px 0;
    flex-direction: row;

    &__top {
      flex: 0 0 50%;
    }

    &__description {
      max-width: 350px;
    }

    &__wrap-image-container {
      transform: scale(1.4);

      &._await-anim {
        transform: scale(1.9);
      }
    }
  }
}

@include respond-down('medium') {
  .slide-column {
    &__slider-items {
      margin-top: 15px;
    }

    &__picture,
    &__wrap-video-container {
      &._await-anim {
        .slide-column {
          &__image,
          &__video {
            transform: scale(1.4);
          }
        }
      }
    }

    &__image,
    &__video {
      transform: scale(1);
      will-change: transform;
      transition: transform 1.4s var(--image-transition),
                  -webkit-transform 1.4s var(--image-transition);
    }
  }
}

@include respond-down('small') {
  .slide-column {
    padding: #{$spacer-small}px 0;

    &__top {
      margin-bottom: #{$spacer-small}px;
    }

    &__description {
      max-width: 540px;
    }
  }
}